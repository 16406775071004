import {MDCTabBar} from '@material/tab-bar';

export function tabBar () {
  window.addEventListener('load', function() {

    if (document.querySelector('.mdc-tab-bar')) {

      var element = document.querySelector('.mdc-tab-bar')

      const tabBar = new MDCTabBar(element);

      var pathname = window.location.pathname;

      if (pathname.includes('/assessment') && !pathname.includes('/assessment_scores')) {
        tabBar.focusOnActivate = false;
      }

      tabBar.activateTab(element.dataset.initalTab);
    }
  });

  window.addEventListener('MDCTabBar:activated', function(e) {

    document.querySelectorAll('.tab-content').forEach (function(tab) {
      if (tab.dataset.tabIndex == e.detail.index) {
        tab.style.display="block";
      }
      else {
        tab.style.display="none";
      }

    });
  });
}

