export function select() {
    window.addEventListener('load', function () {
        if (document.getElementById('role-field')) {
            document.getElementById('user_role').disabled = false;
            const textElement = document.getElementById('role-field').querySelector('.mdc-select__selected-text');
            disableOrganizationFieldIfRoleIsNotOrganizational(textElement);
        }
    });

    window.addEventListener('MDCSelect:change', function (event) {
        if (event.target.id.includes('role')) {
            const textElement = event.target.querySelector('.mdc-select__selected-text');
            disableOrganizationFieldIfRoleIsNotOrganizational(textElement);
        }
    });
}

function disableOrganizationFieldIfRoleIsNotOrganizational(textElement) {
    if (document.getElementById('editable-organization-field')) {
        const organizationField = document.getElementById('editable-organization-field');
        if (textElement.innerText === 'Super Admin' || textElement.innerText === 'System Admin') {
            organizationField.classList.add('mdc-select--disabled');
            document.getElementById('user_organization').disabled = true;
            return;
        }
        document.getElementById('user_organization').disabled = false;
        organizationField.classList.remove('mdc-select--disabled');
    } else if (document.getElementById('read-only-organization-field')) {
        document.getElementById('user_organization').disabled = false;
    }
}
