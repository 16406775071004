
import {appBarNav} from './modules/app_bar_nav';
import {snackbar} from './modules/snackbar';
import {dataTable} from './modules/data_table';
import {ripples} from './modules/ripples';
import {formFields} from './modules/form_fields';
import {tabBar} from './modules/tab_bar';
import {select} from "./modules/select";

import 'material-icons/iconfont/material-icons';
import { drawScoreGauge } from './modules/score_gauge';

appBarNav();
snackbar();
dataTable();
ripples();
formFields();
tabBar();
select();
drawScoreGauge();