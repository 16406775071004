export function drawScoreGauge () {
  window.addEventListener('load', function () { 
    const c = document.getElementById("ari-gauge");
    const ctx = c.getContext("2d");

    // get score value from assessment
    var score = c.dataset.score;
    let scoreValue = Number(score, 1);

    var angle = -(Math.PI - (scoreValue + 2) * Math.PI/6);
    
    // start positions for arc
    const xPosition = 200;
  	const yPosition = 225;
    const radius = 130;
    const lineWidth = 50;
    const indicatorLineWidth = 65;
  

    // score range index
    const smallFont = "14px Arial";
    const largeFont = "24px Arial";
    ctx.font = smallFont;
    // ctx.fillText("-2", 65, 230);

    ctx.fillText("-1", 40, 145);

	  ctx.font = largeFont;
    ctx.fillText("0", 110, 80);
    
    ctx.font = smallFont;
    ctx.fillText("1", 195, 60);

	  ctx.font = largeFont;
    ctx.fillText("2", 280, 80);

    ctx.font = smallFont;
    ctx.fillText("3", 345, 145);

    // ctx.fillText("4", 420, 230);

    // green part
    ctx.beginPath();
    ctx.arc(xPosition, yPosition, radius, Math.PI, -(2*Math.PI)/3, false);
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = "#A1DE93";
    ctx.stroke();
    ctx.closePath();

    // yellow part
    ctx.beginPath();
    ctx.arc(xPosition, yPosition, radius, -(2*Math.PI)/3, -Math.PI/3, false);
    ctx.strokeStyle = "#F7F48B";
    ctx.stroke();
    ctx.closePath();

    // red part
    ctx.beginPath();
    ctx.arc(xPosition, yPosition, radius, -Math.PI/3, 0, false);
    ctx.strokeStyle = "#F47C7C";
    ctx.stroke();
    ctx.closePath();

    // indicator path
    ctx.beginPath();
    ctx.lineWidth = indicatorLineWidth;
    ctx.arc(xPosition, yPosition, radius, angle - Math.PI/120, (angle + Math.PI/120), false);
    ctx.strokeStyle = "#241F21";
    ctx.shadowBlur = 10;
    ctx.shadowColor = "#F9F7F7";
    ctx.stroke();

    // score value 
    ctx.font = '24px Arial';
    ctx.fillText('ARI Score', 145, 190);

    let valueXPosition = scoreValue % 1 != 0 ? 180 : 185;
    valueXPosition = scoreValue < 0 && scoreValue % 1 == 0 ? 185 : 180

    if (Number.isNaN(scoreValue)) {
      ctx.font = '24px Arial';
      ctx.fillStyle = "red";
      ctx.fillText('UN-SCORED', 125, 225);
    } else {
      ctx.font = '28px Arial';
      ctx.fillText(scoreValue, valueXPosition, 225);
    }
  });
}